const COLORS = [
  'coral',
  'firebrick',
  'seagreen',
  'slateblue',
  'slategray',
  'rebeccapurple',
  'teal',
]

export { COLORS }
